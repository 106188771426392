// If you want to override variables do it here
@import 'variables';

// Import styles
@import '~@coreui/coreui/scss/coreui.scss';

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import 'custom';

// ie fixes
@import 'ie-fix';

body {
    background-color: #e9f1e4;
}

.react-bootstrap-table {
    font-size: 10px;
}

.react-bs-table table td,
.react-bs-table table th {
    white-space: normal !important;
}

.sidebar {
    // background-color: #3ab54b;
    background-color: #404040;
}

.sidebar .nav-link.active {
    background-color: #26682f;
}

.sidebar .nav-link:hover {
    background-color: #70ad47;
}

.sidebar .nav-link .nav-icon {
    color: #bbf5d3;
}

.sidebar .nav-link.active .nav-icon {
    color: #eedf1c;
}

.app-header {
    background-color: #ecf0f1;
}

.table th,
.table td {
    padding: 5px;
}

.badge {
    white-space: normal;
}

.badge-warning {
    color: white;
    background-color: #ffc107;
}

.table th,
.table td {
    padding: 0.2rem;
}

a {
    color: #3ab54b;
}

.card-header {
    color: #006837;
}

.app-header {
}

.btn-link {
    color: rgb(141, 197, 63);
}

.btn-link:hover {
    color: rgb(120, 167, 54);
}

.btn-primary {
    background-color: rgb(141, 197, 63);
    border-color: rgb(141, 197, 63);
    line-height: 2.3;
}

.btn-primary:hover {
    background-color: rgb(128, 179, 58);
    border-color: rgb(128, 179, 58);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    background-color: rgb(128, 179, 58);
    border-color: rgb(128, 179, 58);
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: rgb(145, 201, 67);
    border-color: rgb(145, 201, 67);
}

.bg-primary {
    background-color: #65a15e !important;
}

.card-header {
    background-color: #65a15e !important;
    color: white !important;
}

thead tr {
    background-color: #65a15e !important;
    color: white !important;
}

.table-bordered th,
.table-bordered td {
    border: transparent;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(242, 245, 239);
}

.table-striped tbody tr {
    height: auto;
    min-height: 42px;
}

.tabla .card-body {
    padding: 0;
}

@media (min-width: 1200px) {
    .lg-down-ml-auto {
        margin-left: auto !important;
    }
}

@include media-breakpoint-down(sm) {
    // your code
    .table {
        width: 900px;
    }
}

.react-datepicker-wrapper {
    input {
        width: 250px;
    }
}

.btn {
    height: 35px;
    line-height: 1;
}

//Date Piker
.react-datepicker__header {
    background-color: #e9f1e4;
}

.react-datepicker__today-button {
    background-color: #e9f1e4;
}

.react-datepicker__day--selected {
    background-color: #019244;
}

//Login
.login-page {
    .card {
        position: relative;

        text-align: center;
        min-width: 0;
        word-wrap: break-word;
        // background-color: transparent;
        background-color: rgba(255, 255, 255, 0.8);
        background-clip: border-box;
        // border: transparent;
        border-radius: 0.25rem;

        h1 {
            color: black;
            margin-bottom: 30px;
        }

        .btn {
            margin-top: 15px;
        }
    }
}
//btn
.btn-xs {
  padding: 0.10rem 0.5rem;
  font-size: 0.60rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
